import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: "PolyShield",
    url: "https://polyshield.finance/#/farms",
    chef: {
        address: "0x0Ec74989E6f0014D269132267cd7c5B901303306",
        rewardTokenTicker: "SHI3LD",
        abi: Chef,
        rewardTokenFunction: "polyshield",
        pendingRewardsFunction: "pendingShield"
    },
    swap: SwapPlatforms.OneInch
}

export default config;
